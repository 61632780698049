.Resume {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  background-color: #252525;
  width: 100%;
  padding-top: 6rem;

  &-title {
    display: flex;
    font-family: "Forzan", sans-serif;
    color: #fff;
    font-size: 8rem;
  }
  &-instruct {
    color:  #F9F09F;
    font-size: 2rem;
    padding-top: 4rem;
    margin-left: 1rem;
  }
  &-img {
    height: 12rem;
    width: 12rem;
    align-self: center;
    margin-top: 8rem;
    margin-bottom: 4rem;

  }

}

.spn {
    color: #9092CB;
    text-decoration: none;
}