@use "../../Style/Font" as *;


$offwhite: darken(white, 10%);
$darkblue: rgb(50, 50, 60);

.Contact {
  box-sizing: border-box;
  background-color: #252525;
  width: 100%;
  padding-top: 6rem;

  &__title {
    display: flex;
    font-family: "Forzan", sans-serif;
    color: #fff;
    font-size: 8rem;
  }
  &__main {
    display: flex;
  }

  &__right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 60%;
    height: 18rem;
    background-color: #d87446;
    margin-top: 3rem;
    gap: 1rem;
  }

  &__bottom-text {
    font-family: "Forzan", sans-serif;
    color: #252525;
    font-size: 2rem;
  }
  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 70%;
    height: 22rem;
    background-color: #f9f09f;
    margin-left: 4rem;
  }
  &__icons {
    height: 6rem;
    width: 6rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  &__left-side {
    display: flex;
    width: 50%;
    align-items: center;
  }
  &__single-block {
    border-radius: 20px;
    background-color: #9092cb;
    height: 35rem;
    width: 80%;
    margin-left: 2rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: "Forzan", sans-serif;
    margin-top: 3rem;

  }
  &__form-text {
    font-family: "Forzan", sans-serif;
    color: #fff;
    font-weight: 800;
    font-size: 2rem;
    padding-top: 2rem;
  }
  &__name {
    width: 65%;
    height: 4rem;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    padding-left: 1rem;
    margin-top: 2rem;
    font-family: "Forzan", sans-serif;
    font-size: 1rem;
    color: #9092cb;
    
  }
  &__name::placeholder {
    color: #9092cb;
    font-size: 1rem;
    padding-left: .5rem;

  }
  
  &__text {
    font-family: 'Forzan', sans-serif;
    width: 65%;
    height: 8rem;
    border-radius: 10px;
    border: none;
    background-color: #ffff;
    padding-left: 1rem;
    padding-top: 1rem;
    margin-top: 2rem;
    font-size: 1rem;
    color: #9092cb;
  }
  &__text::placeholder {
    color: #9092cb;
    font-size: 1rem;
    padding-left: .5rem;
  }

  &__top-text {
    font-family: 'Forzan', sans-serif;
    color: #ffff;
    font-size: 1.5rem;
    font-weight: 900;
  }

  &__button {
    font-family: 'Forzan', sans-serif;
    font-weight: 600 ;
    letter-spacing: 1px;
    font-size: .9rem;
    width: 35%;
    height: 4rem;
    border-radius: 40px;
    border: none;
    margin-top: 2rem;
    cursor: pointer;
    transition: all .5s ease;
    color: #fff;
    border: 3px solid white;
    text-align: center;
    line-height: 1;
    background-color : transparent;
    outline: none;
}
button:hover {
    color: #9092cb;
    background-color: #fff;
}
// .swal-title {
//   margin: 0px;
//   font-size: 16px;
//   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
//   margin-bottom: 28px;
// }

    
  @keyframes up-bump {
    0% { padding-top: 2rem; }
    50% { padding-top: 1.5rem; }
    100% { padding-top: 2em; }
  }
  @keyframes down-bump {
    0% { padding-top: 2rem; }
    50% { padding-top: 2.5rem; }
    100% { padding-top: 2rem; }
  }
  .swal-title {
    font-size: 1.6rem !important;
   font-family: 'Forzan', sans-serif !important;
    
  }

  // .swal2-font  {
  //   font-size: 1.6rem !important;
  //   font-family: 'Forzan', sans-serif !important;
  // }

//   &__button:hover {
//     background-color: transparent;
//     box-shadow: none;
//     color: $offwhite;
//   }

//   &__button::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border: 3px solid darken($darkblue, 10%);
//     transition: opacity 0.3s, border 0.3s;
//   }

//   &__button:hover::before {
//     opacity: 0;
//   }

//   &__button::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: transparent;
//     border: 3px solid $offwhite;
//     opacity: 0;
//     z-index: -1;
//     transform: scaleX(1.1) scaleY(1.3);
//     transition: transform 0.3s, opacity 0.3s;
//   }

//   &__button__:hover::after {
//     opacity: 1;
//     transform: scaleX(1) scaleY(1);
//   }
}

