.Footer {
    box-sizing: border-box;
    background-color: #252525;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    padding-left: 1rem ;
    &-box {
        display: flex;
    }
    
    &-desc {
        font-family: "Forzan", sans-serif;
        color: #fff;
        font-size: .8rem;
        margin-right: 2rem;
        margin-bottom: 2rem;

    }
}