@use '../../Style/Font' as *;

.HomePage {
    box-sizing: border-box;
    height: 100vh;
    background-color: #252525;
    scroll-behavior: smooth;

&__hero-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    margin-bottom: 4rem;
}

#anim {
    color: #ffff;
    text-decoration: none;  
    font-family: 'Forzan', sans-serif;
    position: relative;
    font-weight: 500;
    top: 30%;  
    width: 24em;
    margin: 0 auto;
    font-size: 4.5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;    
}
}