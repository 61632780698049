@use "../../Style/Font" as *;

.Work {
  box-sizing: border-box;
  background-color: #252525;
  width: 100%;
  padding-top: 6rem;

  &__title {
    display: flex;
    font-family: "Forzan", sans-serif;
    color: #fff;
    font-size: 8rem;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 2rem;
    width: 100%;
    padding-top: 4rem;
  }

  &__sub {
    display: flex;
    flex-direction: column;
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    font-family: "Forzan", sans-serif;
    
  }
  &__video-title {
    color: #989697;
    padding-bottom: 1rem;
  }

  &__video-info {
    padding-bottom: 2rem;
  }

  &__info-title {
    color: #989697;
    padding-top: 1rem;
    font-size: 1rem;
  }
  &__info-techs {
    padding-top: 0.5rem;
    width: 30%;
    color: #ffff;
  }
  &__info-top {
    display: flex;
    justify-content: flex-start;
  }
  &__info-link {
    padding-top: 1rem;
    font-size: 1rem;
    padding-bottom: 1rem;
    color: #ffff;
  }
  &__github-link {
    padding-bottom: 1rem;
    text-decoration: none;
    color: #c382c4;
    font-weight: bold;
  }
}
.video {
  height: 35rem;
  width: 65rem;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 1.2rem 1.2rem 0px #c382c4;
  transition: all 200ms ease-in-out;
  margin-bottom: 2rem;
  &:hover {
    transform: translateX(1rem) translateY(1rem);
    box-shadow: 0px 0px 0px black;
  }
}
