@use '../../Style/Font' as *;

.About {
    box-sizing: border-box;
    background-color: #252525;
    height: auto;
    width: 100%;
    padding-top: 6rem;
    

    &__title {
        display: flex;
        font-family: 'Forzan', sans-serif;
        color: #fff;
        font-size: 8rem;
    }
    &__first-text {
        display: flex;
        padding-top: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
        gap: 1rem;

    }
    &__colorful1 {
        font-family: 'Forzan', sans-serif;
        color: #C382C4;
        font-size: 2rem;
    }
    &__main1 {
        font-family: 'Forzan', sans-serif;
        color:#ffff;
        font-size: 2rem;  
        // opacity: 0;
        //  visibility: hidden;    
    }
    &__second-text {
        display: flex;
        padding-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        gap: 1rem;

    }
    &__main2 {
        font-family: 'Forzan', sans-serif;
        color:#ffff;
        font-size: 2rem;
        // opacity: 0;
        // visibility: hidden;
    }
    &__colorful2 {
        font-family: 'Forzan', sans-serif;
        color: #F9F09F;
        font-size: 2rem;
        // opacity: 0;
        // visibility: hidden;
    }
    // #revealUp {
    //     opacity: 0;
    //     visibility: hidden;
    //   }

    .reveal{
        position: relative !important;
        transform: translateY(150px) !important;
        opacity: 0 !important;
        transition: 1s all ease !important;
      }
      
      .reveal.active{
        transform: translateY(0) !important;
        opacity: 1 !important;
      }
    //   .active.fade-bottom {
    //     animation: fade-bottom 1s ease-in;
    //   }
    //   @keyframes fade-bottom {
    //     0% {
    //       transform: translateY(50px);
    //       opacity: 0;
    //     }
    //     100% {
    //       transform: translateY(0);
    //       opacity: 1;
    //     }
    //   }
}