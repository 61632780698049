
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Forzan', sans-serif;
  /* cursor: url('./Assets/Icons/egg.png'), auto; */
}


