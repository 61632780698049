@use '../../Style/Font' as *;

.Links {
    box-sizing: border-box;
    // background: linear-gradient( to right,
    //     #252525, #252525 50%, #C382C4 50%, #C382C4
    //  );
    background-color: #252525;
    height: 100vh;
    width: 100%;

    &__title {
        display: flex;
        font-family: 'Forzan', sans-serif;
        color: #fff;
        font-size: 8rem;
    }
}