@font-face {
    font-family: 'Forzan';
    src: url('../Assets/Fonts/Forzan-Light.eot');
    src: url('../Assets/Fonts/Forzan-Light.eot?#iefix') format('embedded-opentype'),
        url('../Assets/Fonts/Forzan-Light.woff2') format('woff2'),
        url('../Assets/Fonts/Forzan-Light.woff') format('woff'),
        url('../Assets/Fonts/Forzan-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

