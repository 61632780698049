.NavBar {
    display: flex;
    align-items: baseline;
    

    &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: #ffff;
    cursor: pointer;
}
&__link2 {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: #252525;
}
&__info-block {
    box-sizing: border-box;
    display: flex;
    width: 100%;

}

&__first-block {
    display: flex;
    color:#252525;
    align-items: flex-end;
    margin-left: 1rem;
    height: 18rem;
    width:35%;
    border-radius: 55px;
    justify-content: space-between;
    background: linear-gradient(
        #F9F09F, #F9F09F 50%, #9092CB 50%, #9092CB
     );
       background-size: 100% 202%;
       transition: all 0.2s ease;
       animation: down-bump 0.4s ease;
}
&__first-block:hover{
        border-radius: 40px;
        border-color: #494949 !important;
        background-position: 100% 100%;
            animation: up-bump 0.4s ease;
          
}
&__text {
    font-family: 'Forzan', sans-serif;    
    padding-left: 2.5rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}
&__text1 {
    font-family: 'Forzan', sans-serif;    
    padding-left: 2.5rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #252525;
}
&__icon {
    height: 2rem;
    margin-bottom: 1rem;
    padding-right: 2.5rem;
}
&__blocks-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: flex-end;
    margin-right: 1rem;
    gap:1rem;
}
&__top-block {
    display: flex;
    width: 100%;

}
&__bottom-block {
    display: flex;
    width: 100%;
   
}
&__second-block {
    display: flex;
    margin-left: 1rem;
    align-items: flex-end;
    color: #ffff  ;
    height: 8rem;
    width: 60%;
    border-radius: 55px;
    justify-content: space-between;
    background: linear-gradient(
        #9092CB, #9092CB 50%, #D87446 50%, #D87446
     );
       background-size: 100% 202%;
       transition: all 0.2s ease;
       animation: down-bump 0.4s ease;
}
&__second-block:hover{
        border-radius: 40px;
        border-color: #494949 !important;
        background-position: 100% 100%;
        animation: up-bump 0.4s ease;
}

&__third-block {
    display: flex;
    align-items: flex-end;
    margin-left: 1rem;
    color: #ffff;
    height: 9rem;
    width: 60%;
    border-radius: 55px;
    justify-content: space-between;
    background: linear-gradient(
        #C382C4, #C382C4 50%, #9092CB 50%, #9092CB
     );
       background-size: 100% 202%;
       transition: all 0.2s ease;
       animation: down-bump 0.4s ease;
}
&__third-block:hover{
        border-radius: 40px;
        border-color: #494949 !important;
        background-position: 100% 100%;
        animation: up-bump 0.4s ease;
}
&__fourth-block {
    display: flex;
    align-items: flex-end;
    margin-left: 1rem;
    color: #252525 ;
    height: 8rem;
    width: 35%;
    border-radius: 55px;
    justify-content: space-between;
    background: linear-gradient(
        #D87446, #D87446 50%, #C382C4 50%, #C382C4
     );
       background-size: 100% 202%;
       transition: all 0.2s ease;
       animation: down-bump 0.4s ease;
}
&__fourth-block:hover{
        border-radius: 40px;
        border-color: #494949 !important;
        background-position: 100% 100%;
        animation: up-bump 0.4s ease;
}
&__fifth-block {
    display: flex;
    align-items: flex-end;
    margin-left: 1rem;
    color: #ffff;
    height: 9rem;
    width: 90%;
    border-radius: 55px;
    justify-content: space-between;
    background: linear-gradient(
     #A59E6F, #A59E6F 50%, #F9F09F 50%, #F9F09F
  );
    background-size: 100% 202%;
    transition: all 0.2s ease;
    animation: down-bump 0.4s ease;
}
&__fifth-block:hover{
        border-radius: 40px;
        border-color: #494949 !important;
        background-position: 100% 100%;
        animation: up-bump 0.4s ease;
          
}
@keyframes up-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 1.5em; }
    100% { padding-top: 2em; }
  }
  @keyframes down-bump {
    0% { padding-top: 2em; }
    50% { padding-top: 2.5em; }
    100% { padding-top: 2em; }
  }
}